import styled from 'styled-components';

export const PrimaryNavigationContainer = styled.nav`
  background: transparent;
  width: 100%;
  box-shadow: none;
  border: none;
  height: 78px;
  position: fixed;
  z-index: 100;
  background: #fff;

  .row-max {
    display: flex;
    justify-content: space-between;

    .logo {
      a {
        display: block;
        padding: 9px 0;
      }
      h1 {
        font-weight: 700;
        font-size: 32px;
        margin: 0;
        letter-spacing: -1px;

        span {
          font-weight: 200;
        }
      }
      .sub-logo {
        display: block;
        font-weight: 600;
      }

      a {
        text-decoration: none;
        color: #000;
      }
    }
    .nav-hamburger {
      display: none;
      box-shadow: none;
      border: none;
      padding: 0;
      margin: 0;
      height: 78px;
      width: 44px;
      background: transparent;
      position: relative;

      span {
        height: 3px;
        width: 36px;
        background: #000;
        position: absolute;
        transition: 0.3s all ease;

        &.top {
          top: 27px;
          left: 0;
        }
        &.middle {
          top: 37px;
          left: 8px;
        }
        &.bottom {
          top: 47px;
          left: 0;
        }
      }

      &:hover {
        span {
          &.top {
            left: 4px;
          }
          &.middle {
            left: 4px;
          }
          &.bottom {
            left: 4px;
          }
        }
      }
    }
    .nav-links {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: inline-block;
        line-height: 78px;
        font-size: 18px;
        margin: 0 6px;
        text-transform: capitalize;
        color: #000;

        a {
          font-size: 18px;
          text-decoration: none;
          color: #000;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
    .hamburger {
      display: none;
    }
  }

  @media (max-width: 770px) {
    .row-max {
      .nav-links {
        display: none;
      }
      .nav-hamburger {
        display: block;
      }
    }
  }
`;
