import React from 'react';

const SingleSkill = (props) => {
  return (
    <li className="skill">
      {props.item.link ? (
        <a href={props.item.link}>
          <div className={props.item.name}>
            <img alt="skill" src={props.item.svgUrl} />
          </div>
          <span>{props.item.name}</span>
        </a>
      ) : (
        <>
          <div className={props.item.name}>
            <img alt="skill" src={props.item.svgUrl} />
          </div>
          <span>{props.item.name}</span>
        </>
      )}
    </li>
  );
};

export default SingleSkill;
