import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Homepage from '../pages/homepage/Homepage';
import PrimaryNavigation from '../shared/navigation/PrimaryNavigation';
import Cursor from '../shared/UiElements/cursor/Cursor';
import Footer from '../shared/footer/Footer';
import LoadingSpinner from '../shared/UiElements/LoadingSpinner';

const Contact = React.lazy(() => import('../pages/contact/Contact'));

function App() {
  return (
    <Router>
      <Cursor />
      <PrimaryNavigation />
      <main>
        <Suspense fallback={<div><LoadingSpinner /></div>}>
          <Switch>
            <Route path="/" exact>
              <Homepage />
            </Route>
            <Route path="/contact" exact component={Contact}>
              <Contact />
            </Route>
          </Switch>
        </Suspense>
      </main>
      <Footer />
    </Router>
  );
}

export default App;

//Ways to improve this app
//TS
//There are some duplicate styles
//Do you have any state that re-renders components unnecessarily?

