import styled from 'styled-components';

export const SkillsContainer = styled.div`
    background: #F5F5F5;
    
    > div {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 6rem 0;

    > div {
      display: flex;
      justify-content: center;
    }

    h2 {
        margin: 0 0 3rem;
        font-size: 4rem;
        color: #000;
        text-align: left;
    }

    .select-wrapper {
        position: relative;
        width: 250px;
        box-shadow: 0 10px 30px 4px rgb(46 59 70 / 5%);
        top: -14px;
        left: 32px;
        height: 52px;
        top: 16px;

        &::after {
          color: black;
          content: '▾';
          margin-right: 10px;
          pointer-events: none;
          position: absolute;
          right: 10px;
          top: 11px;
          font-size: 20px;
        }
        select {
            -moz-appearance: none;
            -webkit-appearance: none;
            background: white;
            border: none;
            border-radius: 0;
            cursor: pointer;
            padding: 16px;
            width: 100%;
            font-size: 18px;
            &:focus {
              color: black;
            }
            // Hack for IE 11+
            &::-ms-expand {
              display: none;
            }
          }
      }

    ul.skills {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li.skill {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc(100% / 7);
            margin-bottom: 2rem;

            span {
              font-size: 18px;
              font-weight: 600;
              margin-top: 6px;
              display: block;
              text-align: center;
            }

            div {
                background: #fff;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                box-shadow: 0 10px 30px 4px rgba(46, 59, 70, 0.05);
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 60px;
                    height: 60px;
                }
                &.Typescript {
                  img {
                    width: 54px;
                    height: 54px;
                  }
                }
                &.Ruby {
                  img {
                    width: 52px;
                    height: 52px;
                  }
                }
                &.NodeJS {
                  img {
                    width: 74px;
                  }
                }
                &.Rails {
                  img {
                    width: 74px;
                  }
                }
                &.VueJS {
                  img {
                    position: relative;
                    top: 4px;
                  }
                }
                &.MongoDB {
                  img {
                    width: 90px;
                  }
                }
                &.MySQL {
                  img {
                    width: 68px;
                  }
                }
                &.PostgreSQL {
                  img {
                    width: 74px;
                    height: 74px;
                  }
                }
                &.AWS {
                  img {
                    position: relative;
                    top: 3px;
                  }
                }
                &.Slack {
                  img {
                    width: 90px;
                    height: 24px;
                  }
                }
                &.Jira {
                  img {
                    width: 80px;
                    height: 80px;
                    position: relative;
                    top: 6px;
                    left: 7px;
                  }
                }
                &.Asana {
                  img {
                    width: 90px;
                  }
                }
                &.I.can.help {
                  img {
                    -webkit-transform: rotate(-40deg);
                    -moz-transform: rotate(-40deg);
                    -o-transform: rotate(-40deg);
                    -ms-transform: rotate(-40deg);
                    transform: rotate(-40deg);
                    -webkit-animation: wink 0.5s alternate infinite; /* Safari 4+ */
                      -moz-animation:    wink 0.5s alternate infinite; /* Fx 5+ */
                      -o-animation:      wink 0.5s alternate infinite; /* Opera 12+ */
                      animation:         wink 0.5s alternate infinite; /* IE 10+, Fx 29+ */
                    -webkit-transform-origin: center;
                    -moz-transform-origin: center;
                    -o-transform-origin: center;
                    -ms-transform-origin: center;
                    transform-origin: center;
                    -webkit-animation-timing-function: ease; /* Chrome, Safari, Opera */
                    animation-timing-function: ease;
                  }
                }
            }
        }
    }

  @media (max-width: 1250px) {
    ul.skills {
      li.skill {
        width: calc(100% / 6) !important;
      }
    }
  }
  @media (max-width: 900px) {
    ul.skills {
      li.skill {
        width: calc(100% / 5) !important;
      }
    }
  }
  @media (max-width: 750px) {
    > div {
      flex-direction: column;

      h2 {
        text-align: center;
      }
      .select-wrapper {
        left: 50%;
        margin-left: -125px;
        top: -24px;
      }
    }
    
    ul.skills {
      margin-top: 1rem;
      li.skill {
        width: calc(100% / 4) !important;
      }
    }
  }
  @media (max-width: 600px) {
    ul.skills {
      li.skill {
        width: calc(100% / 3) !important;
      }
    }
  }
  @media (max-width: 480px) {
    ul.skills {
      li.skill {
        width: calc(100% / 2) !important;
      }
    }
  }
`;
