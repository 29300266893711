import React from 'react';
import { Link } from 'react-router-dom';

const CarouselContent = ({ item, index }) => {

  let spriteDivs = [];
  for (var i = 0; i < item.numberOfSprites; i++) {
    spriteDivs.push(<div className={`sprite-image sprite${i}`} key={i}></div>);
  }

  return (
    <div>
      <div>
        <div className="slide-copy  ">
          <span className="line"></span>
          <h2>{item.slideTitle}</h2>
          <h3>{item.slideCopy}</h3>
          <p>{item.slideDesc}</p>
          <button><Link to="/contact">Hire me</Link></button>
        </div>
        
        <div className={`slide-hero-container slide${index}`}>
        {spriteDivs}
        </div>
        
      </div>
    </div>
  );
};

export default CarouselContent;
