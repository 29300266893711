import React, { useCallback, useState } from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useScrollingHook } from '../../hooks/ScrollingHook';
import { FooterContainer } from './FooterStyles';

const Footer = () => {
  const [shiftContact, setShiftContact] = useState(0);

  const listenToScroll = useCallback((e) => {
    const scrollPosition =
      document.body.scrollTop || document.documentElement.scrollTop;

    const portfolioPosition =
      window.scrollY +
      document.querySelector('.footer-anchor').getBoundingClientRect().top;

    const windowHeight = window.innerHeight;
    let scrollEqualiser = scrollPosition + windowHeight - portfolioPosition;
    if (scrollEqualiser) {
      setShiftContact(scrollEqualiser / 10);
    } else {
      setShiftContact(0);
    }
  }, []);

  useScrollingHook(listenToScroll);

  const stylesToRight = {
    transform: `translateX(${shiftContact}px)`,
  };

  return (
    <FooterContainer className="footer-anchor">
      <div>
        {window.location.pathname !== '/contact' && (
          <>
          <div className="row top">
            <h2>Need a trusted developer for your project?</h2>
          </div>
          <div className="row mid">
          <Link to="/contact">
            <button style={stylesToRight}>Hire me</button>
          </Link>
        </div>
        </>
        )}
        
        <div className="row bot">
          <span className="email">
            <a href="mailto:hireme@jameswaterhouse.dev">hireme@jameswaterhouse.dev</a>  <a href="https://www.linkedin.com/in/waterhousejames/"><BsLinkedin /></a> 
          </span>
          <span className="links">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </span>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
