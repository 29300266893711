import styled from 'styled-components';

export const PortfolioContainer = styled.div`
  overflow: hidden;
  margin: 0 0 6rem;

  .title-wrap {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 6rem 0 0;
  }

  h2 {
    margin: 0 0 3rem;
    font-size: 4rem;
    color: #000;
    text-align: center;
  }

  .portfolio {

    > div {
      padding: 40px 24px;
      float: left;
      margin: 0 24px;
      img {
        max-width: 500px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &.top {
      margin-bottom: 48px;
      margin-left: -250px;
      width: 200%;
    }
    &.bottom {
      margin-right: -250px;
      width: 200%;
    }
  }

  @media (max-width: 2100px) {
    .portfolio {
      > div {
        img {
          max-width: 470px;
        }
      }
    }
  }

  @media (max-width: 1960px) {
    .portfolio {
      > div {
        img {
          max-width: 440px;
        }
      }
    }
  }

  @media (max-width: 1800px) {
    .portfolio {
      > div {
        img {
          max-width: 400px;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .portfolio {
      > div {
        img {
          max-width: 360px;
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .portfolio {
      > div {
        img {
          max-width: 320px;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .portfolio {
      > div {
        margin: 0 12px;
        img {
          max-width: 300px;
        }
      }
    }
    .title-wrap {
      max-width: 94%;
      margin: 0 3%;
    }
  }

  @media (max-width: 1200px) {
    .portfolio {
      > div {
        margin: 0 12px;
        img {
          max-width: 280px;
        }
      }
    }
  }
  @media (max-width: 900px) {
    .portfolio {
      > div {
        padding: 24px 16px;
        img {
          max-width: 260px;
        }
      }
      &.top {
        margin-left: -50px;
      }
    }
  }
  @media (max-width: 770px) {
    .portfolio {
      &.top {
        margin-left: -150px;
        .portfolio-item-0 {
          display: none;
        }
      }
      &.bottom {
        .portfolio-item-7 {
          display: none;
        }
      }
    }
  }

  @media (max-width: 670px) {
    .portfolio {
      > div {
        img {
          max-width: 220px;
        }
      }
    }
  }

  @media (max-width: 570px) {
    .portfolio {
      > div {
        padding: 24px 12px;
        margin: 0 6px;

        
        img {
          max-width: 200px;
        }
      }
      &.top {
        width: 220%;
      }
      &.bottom {
        width: 220%;
      }
    }
  }

  @media (max-width: 450px) {
    .portfolio {
      > div {
        
        img {
          max-width: 180px;
        }
      }
      &.top {
        width: 230%;
      }
      &.bottom {
        width: 230%;
      }
    }
  }
`;
