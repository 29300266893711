import styled from 'styled-components';

export const CursorContainer = styled.div`
  .ring {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    transition: .3s width ease, .3s height ease;
    transform-origin: center;
    will-change: width, height, transform, border;
    z-index: 999;
    pointer-events: none;

    .cursor-content {
      display: none;
    }

    &.hovered {
      width: 50px;
      height: 50px;
      border-width: 3px;
      background-color: rgba(31, 30, 30, 0.4);
    }
    &.header-scroll-hovered {
      cursor: pointer;
      width: 72px;
      height: 72px;
      background-color: #65cf96;
      display: flex;
      justify-content: center;
      align-items: center;

      .cursor-content.header-scroll-hovered-child {
        display: block;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
    }
    &.timeline-hovered {
      cursor: pointer;
      width: 82px;
      height: 82px;
      line-height: 1;
      background-color: #65cf96;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center;

      .cursor-content.timeline-hovered-child {
        display: block;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
    }
    &.portfolio-hovered {
      cursor: pointer;
      width: 82px;
      height: 82px;
      line-height: 1;
      background-color: #65cf96;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align:center;

      .cursor-content.portfolio-hovered-child {
        display: block;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

`;
