import React, { useState } from 'react';
import Modal from '../../../shared/modals/Modal';
import { GoPlus } from 'react-icons/go';
import { BiRightArrowAlt } from 'react-icons/bi';
import { BsArrowLeft, BsArrowRight} from 'react-icons/bs';


const ModalContent = ({
  item,
  index,
  closeTimelineCard,
  showTimelineCard,
  timeline,
}) => {

  const [modalJob, setModalJob] = useState(item);

  const closeTimelineCardHandler = () => {
    closeTimelineCard();
  };

  const nextClickedHandler = () => {
    const indexById = timeline.findIndex((e) => e._id === modalJob._id);
    let theNextJob = timeline[indexById + 1];
    if (theNextJob === undefined) {
      theNextJob = timeline[indexById - (timeline.length - 1)];
    }
    setModalJob(theNextJob);
  };

  const prevClickedHandler = () => {
    const indexById = timeline.findIndex((e) => e._id === modalJob._id);
    let thePrevJob = timeline[indexById- 1];
    if (thePrevJob === undefined) {
        thePrevJob = timeline[indexById + (timeline.length - 1)];
    }
    setModalJob(thePrevJob);
  };

  return (
    <Modal
      show={showTimelineCard}
      onCancel={closeTimelineCardHandler}
      contentClass="classgoeshere"
      footer={<button onClick={closeTimelineCardHandler}>Close</button>}
    >
      <div className="exit-button" onClick={closeTimelineCardHandler}>
        <GoPlus />
      </div>
      <button className="prev modal-btn" onClick={prevClickedHandler}>
        <BsArrowLeft />
      </button>
      <button className="next modal-btn" onClick={nextClickedHandler}>
        <BsArrowRight />
      </button>

      <div className="timeline-card-header">
        <h3>{modalJob.role}</h3>
        <img alt={index} src={modalJob.logoUrl} />
      </div>
      <div className="timeline-card-body">
        <p className="dates">{modalJob.dates}</p>
        <p className="short-desc">{modalJob.shortDescription}</p>
        <ul className="description">         
          {modalJob.description.map((paragraph, idx) => {
            return <li key={idx}>{paragraph}</li>
          })}
        </ul>
        <button>
          <a href="https://www.linkedin.com/in/waterhousejames/">
          View LinkedIn <BiRightArrowAlt />
          </a>
        </button>
      </div>
    </Modal>
  );
};

export default ModalContent;
