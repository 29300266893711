import styled from 'styled-components';

export const ModalContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -215px 0 0 -360px;
  width: 720px;
  height: 430px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;

  .exit-button {
    position: absolute;
    top: -20px;
    right: -20px;
    background: #000;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 101;

    svg {
      width: 32px;
      height: 32px;
      position: relative;
      top: 4px;
      left: 4px;
      transform: rotate(45deg);
      fill: #fff;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .modal-btn {
    box-shadow: none;
    border: none;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 100%;
    margin-top: -48px;

    svg {
      width: 20px;
      height: 20px;
      position: relative;
      top: 1px;
    }

    &.prev {
      left: 50%;
      margin-left: -48px;
    }
    &.next {
      right: 50%;
      margin-right: -48px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .timeline-card-header {
    padding: 2rem 2rem 1rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    position: relative;
    display: flex;
    justify-content: space-between;

    h3 {
      margin: 0;
      font-size: 2rem;
      font-weight: 700;
      display: inline-block;
    }
    img {
      height: 42px;
      position: relative;
      top: 3px;
    }
  }
  .timeline-card-body {
    padding: 0 2rem 2rem;

    p.dates {
      margin-top: 0;
      font-weight: 700;
    }
    p.short-desc {
      font-weight: 700;
      font-style: italic;
    }

    p.description {
      margin: 0;
      width: 80%;
    }
    button {
      color: #fff;
      font-size: 13px;
      font-weight: 700;
      box-shadow: none;
      height: 32px;
      border: none;
      padding: 0;
      margin: 0;
      width: 120px;
      border-radius: 5px;
      background: linear-gradient(to right, #5ee19b, #65cf96);
      margin-top: 1rem;

      a {
        color: #fff;
        text-decoration: none;
      }

      svg {
        position: relative;
        top: 2px;
      }
    }
  }

  @media (max-width: 780px) {
    left: 0;
    margin: -265px 2% 0;
    width: 96%;
    height: 530px;
  }

  @media (max-width: 630px) {
    margin: -315px 2% 0;
    height: 630px;

    .exit-button {
      top: -10px;
      right: -10px;
    }

    .timeline-card-header {
      padding: 1rem;
    }
    .timeline-card-body {
      padding: 0 1rem 1rem;

      ul {
        padding-left: 1rem;
      }
      button {
        margin: 0;
      }
    }
  }
`;
