import React, { useContext, useState } from 'react';
import Slider from 'react-slick';
import CarouselContent from './CarouselContent';
import { CarouselContainer } from './CarouselStyles';
import { BsArrowDown } from 'react-icons/bs';
import { MouseContext } from '../../../context/MouseContext';

const CAROUSEL_CONTENT = [
  {
    slideTitle: 'Hi, I am James',
    slideCopy: 'I love to create beautiful user experiences.',
    slideDesc:
    'I am a full stack engineer living in central London, focussed on React and front-end development. I help companies from all over the world achieve their digital goals.',
    imageUrl: `${process.env.REACT_APP_BACKEND_URL}/assets/images/Biscuit.jpg`,
    navImage: `${process.env.REACT_APP_BACKEND_URL}/assets/images/BiscuitSQ.jpg`,
    numberOfSprites: 11,
  },
  {
    slideTitle: 'I love to cook',
    slideCopy: 'I am always ready to step up to the plate.',
    slideDesc: 'I take each project by the horns, seeking postive and impactful solutions to any problems.',
    imageUrl: `${process.env.REACT_APP_BACKEND_URL}/assets/images/last-mealSQ550_comp.png`,
    navImage: `${process.env.REACT_APP_BACKEND_URL}/assets/images/last-mealSQ550_comp.png`,
    numberOfSprites: 11,
  },
  {
    slideTitle: 'I love to snowboard',
    slideCopy: 'I give my best to every client, on every project.',
    slideDesc:
      'I have designed digital products from end to end, being bold, taking responsibility and creating new frontiers.',
    imageUrl: `${process.env.REACT_APP_BACKEND_URL}/assets/images/me-snbdSQ550_comp.jpg`,
    navImage: `${process.env.REACT_APP_BACKEND_URL}/assets/images/me-snbdSQ550_comp.jpg`,
    numberOfSprites: 11,
  },
];

const Carousel = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const { cursorChangeHandler } = useContext(MouseContext);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 6000,
    arrows: false,
    useCSS: false,
    pauseOnHover: false,
  };

  const handleClick = () => {
		document.getElementById('to-experience').scrollIntoView({
			behavior: 'smooth',
		});
	};

  return (
    <CarouselContainer>
      <Slider
        className="header-slider"
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1)}
        {...settings}
      >
        {CAROUSEL_CONTENT.map((item, index) => {
          return <CarouselContent key={index} item={item} index={index} />;
        })}
      </Slider>

      <div
        className="header-scroll cursor-hover"
        onMouseEnter={() => cursorChangeHandler('header-scroll-hovered')}
        onMouseLeave={() => cursorChangeHandler('')}
        onClick={handleClick}
      >
        <BsArrowDown />
      </div>
      <Slider
        className="slide-nav"
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
      >
        {CAROUSEL_CONTENT.map((image, index) => {
          return (
            <div key={index}>
              <div className="slide-nav-item">
                <svg height="100" width="100">
                  <circle
                    className="circle"
                    cx="40"
                    cy="40"
                    r="35"
                    stroke="#231f20"
                    strokeWidth="1"
                    fillOpacity="0"
                  />
                </svg>
                <img alt={index} src={image.navImage} />
              </div>
            </div>
          );
        })}
      </Slider>
    </CarouselContainer>
  );
};

export default Carousel;
