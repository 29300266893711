import React, { useCallback, useContext, useState } from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import { MouseContext } from '../../../context/MouseContext';
import { useScrollingHook } from '../../../hooks/ScrollingHook';
import ModalContent from './ModalContent';

const TimelineCard = ({ item, index, timeline }) => {
  const [showTimelineCard, setShowTimelineCard] = useState(false);
  const [isVisible, setIsVisible] = useState('not-visible');

  const { cursorChangeHandler } = useContext(MouseContext);

  const listenToScroll = useCallback(
    (e) => {
      const scrollPosition =
        document.body.scrollTop || document.documentElement.scrollTop;
      const cardPosition =
        window.scrollY +
        document.querySelector(`.card${index}`).getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (scrollPosition + windowHeight > cardPosition + 60) {
        setIsVisible('visible');
      } else {
        setIsVisible('not-visible');
      }
    },
    [index]
  );

  useScrollingHook(listenToScroll);

  const openTimelineCardHandler = () => setShowTimelineCard(true);
  const closeTimelineCard = () => setShowTimelineCard(false);

  const truncate = (str) => {
    return str.length > 50 ? str.substring(0, 80) + "..." : str;
  }

  return (
    <React.Fragment>
      <ModalContent
        item={item}
        index={index}
        closeTimelineCard={closeTimelineCard}
        showTimelineCard={showTimelineCard}
        timeline={timeline}
      />
      <div
        className={`timeline-card card${index} ${
          index % 2 === 0 ? 'left' : 'right'
        } ${isVisible}`}
        onClick={openTimelineCardHandler}
        onMouseEnter={() => cursorChangeHandler('timeline-hovered')}
        onMouseLeave={() => cursorChangeHandler('')}
      >
        <div className="timeline-card-header">
          <h3>{item.role}</h3>
          <img alt={index} src={item.logoUrl} />
          <span className="year">{item.year}</span>
        </div>
        <div className="timeline-card-body">
          <p>{truncate(item.shortDescription)}</p>
          <button>
            Learn more <BiRightArrowAlt />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TimelineCard;

//On card hover - change to custom cursor
