import React, { useContext } from 'react';
import { MouseContext } from '../../../context/MouseContext';

const PortfolioItem = ({ item, idx }) => {
  const { cursorChangeHandler } = useContext(MouseContext);

  return (
    <div 
        className={`portfolio-item-${idx}`}
        style={{ background: `${item.bgColour}` }}
        onMouseEnter={() => cursorChangeHandler('portfolio-hovered')}
        onMouseLeave={() => cursorChangeHandler('')}    
    >
        <a href={item.url} target="_blank" rel="noopener noreferrer"><img alt="idx" src={item.imageUrl} /></a>
      
    </div>
  );
};

export default PortfolioItem;
