import React from 'react';
import { Link } from 'react-router-dom';

const NavLink = ({ linkText, navLinkId, scrollToId }) => {
  const handleClick = () => {
    document.getElementById(scrollToId).scrollIntoView({
      behavior: 'smooth',
    });
  };

  let isContact;
  if (linkText === 'hire me') {
    isContact = true;
  } else {
    isContact = false;
  }

  return (
    <>
      {isContact ? (
        <li><Link to="/contact">{linkText}</Link></li>
      ) : (
        <li id={navLinkId} onClick={handleClick}>
          {linkText}
        </li>
      )}
    </>
  );
};

export default NavLink;
