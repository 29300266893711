import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import NavLink from './NavLink';

const NavLinks = () => {

    const navLinks = [
        {
            linkText: 'my experience',
            navLinkId: 'exp',
            scrollToId: 'to-experience'
        },
        {
            linkText: 'my work',
            navLinkId: 'work',
            scrollToId: 'to-work'
        },
        {
            linkText: 'my skills',
            navLinkId: 'skills',
            scrollToId: 'to-skills'
        },
        {
            linkText: 'hire me',
            navLinkId: 'skicontactlls'
        },
    ]

    const withouSidebarRoutes = ["/contact"];

    const {pathname} = useLocation();
        
    if (withouSidebarRoutes.some((item) => pathname.includes(item))) return (
        <ul className='nav-links'>
            <li><Link to="/">Home</Link></li>

        </ul>
    );
    

    return (
        <>
        <ul className='nav-links'>
            {navLinks.map(({linkText, navLinkId, scrollToId}, i) => {
                return (
                    <NavLink key={i} linkText={linkText} navLinkId={navLinkId} scrollToId={scrollToId} />
                )
            })}
        </ul>
        </>
    );
};

export default NavLinks;