import styled from 'styled-components';

export const CarouselContainer = styled.div`
  background: #fff;
  height: calc(100vh - 78px);
  min-height: 720px;
  position: relative;
  padding-top: 78px;

  .header-scroll {
    position: absolute;
    bottom: 120px;
    left: 50%;
    margin-left: -50px;
    z-index: 1;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  .header-slider {
    height: 100%;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
      }

      .slick-slide {
        > div {
          height: 100%;

          > div {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;

            > div {
              position: relative;
              top: -39px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              max-width: 1280px;
              margin: 0 auto;
              width: 100%;

              .slide-copy {
                position: relative;
                z-index: 1;
                width: 560px;

                .line {
                  width: 32px;
                  height: 2px;
                  background: #000;
                  display: inline-block;
                  margin-right: 8px;
                  position: relative;
                  top: -7px;
                  transform: translateY(50px);
                  opacity: 0;
                  transition: 1s all ease;
                  transition-delay: 0.15s;
                }
                h2 {
                  margin: 0;
                  font-size: 1.3rem;
                  color: #000;
                  display: inline-block;
                  transform: translateY(50px);
                  opacity: 0;
                  transition: 1s all ease;
                  transition-delay: 0.15s;
                }
                h3 {
                  font-size: 4rem;
                  font-weight: 900;
                  line-height: 1.1;
                  color: #000;
                  margin: 8px 0 0;
                  transform: translateY(50px);
                  opacity: 0;
                  transition: 1s all ease;
                  transition-delay: 0.3s;
                }
                p {
                  font-size: 1.2rem;
                  color: #000;
                  transform: translateY(50px);
                  opacity: 0;
                  transition: 1s all ease;
                  transition-delay: 0.45s;
                }
                button {
                  box-shadow: none;
                  height: 60px;
                  border: none;
                  padding: 0;
                  margin: 0;
                  width: 200px;
                  border-radius: 5px;
                  background: linear-gradient(to right, #5ee19b, #65cf96);
                  transform: translateY(50px);
                  opacity: 0;
                  transition: 1s all ease;
                  transition-delay: 0.6s;

                  a {
                    padding: 17px 65px;
                    text-decoration: none;
                    font-size: 1.2rem;
                    color: #fff;
                    font-weight: 700;
                  }
                }
              }
              .slide-hero-container {
                position: relative;

                .sprite-image {
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 9px;
                }

                &.slide0 {
                  width: 512px;
                  height: 642px;

                  .sprite-image {
                    background: url(https://jamododger.herokuapp.com/assets/images/Biscuit.jpg);
                    background-repeat: no-repeat;
                    transform: translate(25px, 25px);
                    opacity: 0;
                    transition: 1s all ease;
                  }
                  .sprite0 {
                    width: 80px;
                    height: 110px;
                    background-position: -60px -90px;
                    left: 60px;
                    top: 80px;
                    transition-delay: 0.6s;
                  }
                  .sprite1 {
                    width: 110px;
                    height: 150px;
                    background-position: -30px -200px;
                    left: 30px;
                    top: 196px;
                    transition-delay: 0.45s;
                  }
                  .sprite2 {
                    width: 140px;
                    height: 120px;
                    background-position: 0px -350px;
                    left: 0;
                    top: 352px;
                    transition-delay: 0.45s;
                  }
                  .sprite3 {
                    width: 100px;
                    height: 130px;
                    background-position: -40px -470px;
                    left: 40px;
                    top: 478px;
                    transition-delay: 0.3s;
                  }
                  .sprite4 {
                    width: 160px;
                    height: 220px;
                    background-position: -140px -10px;
                    left: 146px;
                    top: 0;
                    transition-delay: 0.45s;
                  }
                  .sprite5 {
                    width: 160px;
                    height: 170px;
                    background-position: -140px -230px;
                    left: 146px;
                    top: 226px;
                    transition-delay: 0.3s;
                  }
                  .sprite6 {
                    width: 160px;
                    height: 240px;
                    background-position: -140px -400px;
                    left: 146px;
                    top: 402px;
                    transition-delay: 0.15s;
                  }
                  .sprite7 {
                    width: 90px;
                    height: 80px;
                    background-position: -300px -77px;
                    left: 312px;
                    top: 67px;
                    transition-delay: 0.45s;
                  }
                  .sprite8 {
                    width: 160px;
                    height: 165px;
                    background-position: -300px -157px;
                    left: 312px;
                    top: 153px;
                    transition-delay: 0.3s;
                  }
                  .sprite9 {
                    width: 200px;
                    height: 140px;
                    background-position: -300px -320px;
                    left: 312px;
                    top: 324px;
                    transition-delay: 0.15s;
                  }
                  .sprite10 {
                    width: 160px;
                    height: 130px;
                    background-position: -300px -460px;
                    left: 312px;
                    top: 470px;
                    transition-delay: 0;
                  }
                }

                &.slide1 {
                  width: 562px;
                  height: 482px;

                  .sprite-image {
                    background: url(https://jamododger.herokuapp.com/assets/images/last-mealSQ550_comp.png);
                    background-repeat: no-repeat;
                    transform: translate(25px, -25px);
                    opacity: 0;
                    transition: 1s all ease;
                  }

                  .sprite0 {
                    width: 130px;
                    height: 110px;
                    background-position: -55px -138px;
                    left: 54px;
                    top: 58px;
                    transition-delay: 0.45s;
                  }
                  .sprite1 {
                    width: 94px;
                    height: 146px;
                    background-position: -185px -102px;
                    left: 190px;
                    top: 22px;
                    transition-delay: 0.3s;
                  }
                  .sprite2 {
                    width: 154px;
                    height: 168px;
                    background-position: -279px -80px;
                    left: 290px;
                    top: 0;
                    transition-delay: 0.15s;
                  }
                  .sprite3 {
                    width: 100px;
                    height: 95px;
                    background-position: -433px -153px;
                    left: 450px;
                    top: 73px;
                    transition-delay: 0s;
                  }
                  .sprite4 {
                    width: 200px;
                    height: 180px;
                    background-position: -1px -248px;
                    left: 0;
                    top: 174px;
                    transition-delay: 0.45s;
                  }
                  .sprite5 {
                    width: 150px;
                    height: 180px;
                    background-position: -201px -248px;
                    left: 206px;
                    top: 174px;
                    transition-delay: 0.3s;
                  }
                  .sprite6 {
                    width: 200px;
                    height: 180px;
                    background-position: -351px -248px;
                    left: 362px;
                    top: 174px;
                    transition-delay: 0.15s;
                  }
                  .sprite7 {
                    width: 66px;
                    height: 66px;
                    background-position: -31px -428px;
                    left: 30px;
                    top: 360px;
                    transition-delay: 0.6s;
                  }
                  .sprite8 {
                    width: 90px;
                    height: 90px;
                    background-position: -99px -428px;
                    left: 102px;
                    top: 360px;
                    transition-delay: 0.45s;
                  }
                  .sprite9 {
                    width: 170px;
                    height: 126px;
                    background-position: -189px -428px;
                    left: 198px;
                    top: 360px;
                    transition-delay: 0.45s;
                  }
                  .sprite10 {
                    width: 152px;
                    height: 90px;
                    background-position: -359px -428px;
                    left: 376px;
                    top: 360px;
                    transition-delay: 0.3s;
                  }
                }
                &.slide2 {
                  width: 562px;
                  height: 500px;

                  .sprite-image {
                    background: url(https://jamododger.herokuapp.com/assets/images/me-snbdSQ550_comp.jpg);
                    background-repeat: no-repeat;
                    transform: translate(-25px, -25px);
                    opacity: 0;
                    transition: 1s all ease;
                  }

                  .sprite0 {
                    width: 70px;
                    height: 70px;
                    background-position: -38px -92px;
                    left: 38px;
                    top: 70px;
                    transition-delay: 0s;
                  }
                  .sprite1 {
                    width: 130px;
                    height: 100px;
                    background-position: -108px -62px;
                    left: 114px;
                    top: 40px;
                    transition-delay: 0.15s;
                  }
                  .sprite2 {
                    width: 132px;
                    height: 140px;
                    background-position: -238px -22px;
                    left: 250px;
                    top: 0;
                    transition-delay: 0.3s;
                  }
                  .sprite3 {
                    width: 130px;
                    height: 90px;
                    background-position: -370px -72px;
                    left: 388px;
                    top: 50px;
                    transition-delay: 0.45s;
                  }
                  .sprite4 {
                    width: 170px;
                    height: 180px;
                    background-position: 0 -162px;
                    left: 0;
                    top: 146px;
                    transition-delay: 0.15s;
                  }
                  .sprite5 {
                    width: 210px;
                    height: 180px;
                    background-position: -170px -162px;
                    left: 176px;
                    top: 146px;
                    transition-delay: 0.3s;
                  }
                  .sprite6 {
                    width: 170px;
                    height: 180px;
                    background-position: -380px -162px;
                    left: 392px;
                    top: 146px;
                    transition-delay: 0.45s;
                  }
                  .sprite7 {
                    width: 170px;
                    height: 120px;
                    background-position: -30px -342px;
                    left: 30px;
                    top: 332px;
                    transition-delay: 0.3s;
                  }
                  .sprite8 {
                    width: 170px;
                    height: 180px;
                    background-position: -200px -342px;
                    left: 206px;
                    top: 332px;
                    transition-delay: 0.45s;
                  }
                  .sprite9 {
                    width: 80px;
                    height: 110px;
                    background-position: -370px -342px;
                    left: 382px;
                    top: 332px;
                    transition-delay: 0.45s;
                  }
                  .sprite10 {
                    width: 70px;
                    height: 70px;
                    background-position: -440px -342px;
                    left: 468px;
                    top: 332px;
                    transition-delay: 0.6s;
                  }
                }
              }
            }
          }
        }
        &.slick-current {
          .slide-copy {
            .line {
              transform: translateY(0);
              opacity: 1;
            }
            h2 {
              transform: translateY(0);
              opacity: 1;
            }
            h3 {
              transform: translateY(0);
              opacity: 1;
            }
            p {
              transform: translateY(0);
              opacity: 1;
            }
            button {
              transform: translateY(0);
              opacity: 1;
            }
          }
          .slide-hero-container {
            .sprite-image {
              transform: translate(0, 0) !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
  }
  .slide-nav {
    position: relative;
    top: -120px;
    left: 0;
    width: 1280px;
    margin: 0 auto;

    .slick-list {
      .slick-track {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .slick-slide {
          width: 92px !important;

          .slide-nav-item {
            position: relative;

            &:hover {
              cursor: pointer;
            }

            img {
              position: absolute;
              top: 9px;
              left: 9px;
              width: 62px;
              border-radius: 50%;
              transform: scale(1);
              transition: 0.5s all ease;
              filter: grayscale(100%);
            }
            .circle {
              opacity: 0;
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
            }

            @keyframes stroke {
              to {
                stroke-dashoffset: 0;
              }
            }

            @keyframes fadeIn {
              to {
                opacity: 1;
              }
            }
          }
          &.slick-current {
            .slide-nav-item {
              .circle {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: stroke 18000ms ease-in-out forwards;
                opacity: 1;
              }
              img {
                transform: scale(0.8);
                filter: none;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1380px) {
    .header-slider {
      .slick-list {
        .slick-slide {
          > div {
            > div {
              > div {
                width: 96%;
                margin: 0 2%;

                .slide-copy {
                  width: 490px;

                  h2 {
                    font-size: 1.2rem;
                  }
                  h3 {
                    font-size: 3.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    .slide-nav {
      width: 96%;
      margin: 0 2%;
    }
  }

  @media (max-width: 1140px) {
    height: 960px;
    padding-top: 96px;

    .header-scroll {
      left: auto;
      right: auto;
      left: 50%;
      margin-left: 108px;
      bottom: 42px;
    }
    .slide-nav {
      left: 50%;
      margin: 0;
      margin-left: -184px;
      width: 278px;
    }

    .header-slider {
      .slick-list {
        .slick-slide {
          > div {
            > div {
              > div {
                flex-direction: column;

                .slide-copy {
                  width: 600px;
                  text-align: center;

                  h2 {
                    font-size: 1.2rem;
                  }
                  h3 {
                    font-size: 3rem;
                  }
                }
                .slide-hero-container {
                  margin-top: 2rem;
                  transform: scale(0.8);
                  margin-top: 0;
                  position: relative;
                  top: -16px;
                }
                .slide-hero-container.slide0 {
                  height: 514px;
                  transform: scale(0.7);
                  top: -36px;
                }
                .slide-hero-container.slide1 {
                  height: 390px;
                }
                .slide-hero-container.slide2 {
                  height: 410px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 700px) {
    .header-slider {
      .slick-list {
        .slick-slide {
          > div {
            > div {
              > div {
                .slide-copy {
                  width: 96%;
                  margin: 0 auto;
                }
                .slide-hero-container.slide0 {
                  transform: scale(0.65);
                  top: -52px;
                }
                .slide-hero-container.slide1 {
                  transform: scale(0.75);
                }
                .slide-hero-container.slide2 {
                  transform: scale(0.75);
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .header-slider {
      .slick-list {
        .slick-slide {
          > div {
            > div {
              > div {
                .slide-copy {
                  h2 {
                    font-size: 1.1rem;
                  }
                  .line {
                    top: -6px;
                  }
                  h3 {
                    font-size: 2.6rem;
                  }
                  p {
                    font-size: 1.1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 450px) {
    .header-slider {
      .slick-list {
        .slick-slide {
          > div {
            > div {
              > div {
                .slide-hero-container.slide0 {
                  transform: scale(0.65);
                  top: -52px;
                }
                .slide-hero-container.slide1 {
                  transform: scale(0.65);
                }
                .slide-hero-container.slide2 {
                  transform: scale(0.65);
                }
              }
            }
          }
        }
      }
    }
  }
`;
