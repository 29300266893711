import React from "react";
import { useContext } from "react";
import { MouseContext } from "../../../context/MouseContext";
import useMousePosition from "../UseMousePosition";
import { CursorContainer } from "./CursorStyles";

const Cursor = () => {
const { cursorType } = useContext(MouseContext);
const { x, y } = useMousePosition();

let content;
if (cursorType === "header-scroll-hovered") {
  content = "Scroll";
} else if (cursorType === "timeline-hovered") {
  content = "Learn More";
} else if (cursorType === "portfolio-hovered") {
  content = "Visit";
}

  return (
    <CursorContainer>
      <div
        className={`ring ${cursorType}`} 
        style={{ left: `${x}px`, top: `${y}px` }}
      >
        <div className={`cursor-content ${cursorType}-child`}>
          {content}
        </div>
      </div>
      
    </CursorContainer>
  );
};

export default Cursor;