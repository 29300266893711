import React, { useCallback, useEffect, useState } from 'react';
import TimelineCard from './TimelineCard';
import { TimelineContainer } from './TimelineStyles';
import LoadingSpinner from '../../../shared/UiElements/LoadingSpinner';
import { useScrollingHook } from '../../../hooks/ScrollingHook';
import { useHttpHook } from '../../../hooks/HttpHook';
import ErrorModal from '../../../shared/modals/ErrorModal';

const FAKE_CARD = {
  _id: 1,
  role: 'My Next Role',
  company: 'Your company',
  dates: 'Aug 2022',
  year: '2022',
  logoUrl: `${process.env.REACT_APP_BACKEND_URL}/assets/images/yourlogo-2.png`,
  shortDescription:
    'I am currently looking for new development opportunites.',
  description: [
    'I am an inventive, persuasive and resilient problem solver.',
    'Always learning and helping others.',
    'Eager to tackle complex problems and drive projects forward.'
  ],
};

const Timeline = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpHook();
  const [loadedExperiences, setLoadedExperiences] = useState();
  const [shiftTimeline, setShiftTimeline] = useState(0);

  useEffect(() => {
    const fetchTimeline = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/experiences/`
        );
        let experiencesObj =  [...responseData.experiences].sort((a, b) => a.order > b.order ? 1 : -1 );
        experiencesObj = [FAKE_CARD, ...experiencesObj];
        setLoadedExperiences(experiencesObj);

        
      } catch (err) {}
    };
    fetchTimeline();
  }, [sendRequest]);

 

  const listenToScroll = useCallback((e) => {
    const scrollPosition =
      document.body.scrollTop || document.documentElement.scrollTop;

    const portfolioPosition =
      window.scrollY +
      document.querySelector('#to-experience').getBoundingClientRect().top;

    const windowHeight = window.innerHeight;

    let scrollEqualiser =
      scrollPosition + windowHeight - portfolioPosition - 300;
    if (scrollEqualiser > 0) {
      setShiftTimeline(scrollEqualiser);
    } else {
      setShiftTimeline(0);
    }
  }, []);

  useScrollingHook(listenToScroll);

  const stylesDown = {
    height: `${shiftTimeline}px`,
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TimelineContainer className="section">
        <div id="to-experience">
          {isLoading ? (
            <div className="center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <h2>My Experience</h2>
              <div>
                <div className="timeline" style={stylesDown}></div>
                {!isLoading &&
                  loadedExperiences &&
                  loadedExperiences.map((item, index) => {
                    return (
                      <TimelineCard
                        key={index}
                        item={item}
                        index={index}
                        timeline={loadedExperiences}
                      />
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </TimelineContainer>
    </>
  );
};

export default Timeline;
