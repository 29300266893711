import React from 'react';
import ReactDOM from 'react-dom';
import {BackdropContainer} from './BackdropStyles';

const Backdrop = props => {
    return ReactDOM.createPortal(
        <BackdropContainer className="backdrop" onClick={props.onClick} />,
        document.getElementById('backdrop-hook')
      );
};

export default Backdrop;