import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../shared/UiElements/LoadingSpinner';
import SingleSkill from './SingleSkill';
import { SkillsContainer } from './SkillsStyles';
import { useHttpHook } from '../../../hooks/HttpHook';
import ErrorModal from '../../../shared/modals/ErrorModal';


const WAVING = {
  name: 'I can help',
  svgUrl: `${process.env.REACT_APP_BACKEND_URL}/assets/svgs/waving.svg`,
  type: 'all',
  link: '/contact'
};

const Skills = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpHook();
  const [loadedSkills, setLoadedSkills] = useState();
  const [skillType, setSkillType] = useState('all');

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/skills/`
        );
        setLoadedSkills(responseData.skills);
      } catch (err) {}
    };
    fetchSkills();
  }, [sendRequest]);

  const changeTargetValue = (event) => {
    setSkillType(event.target.value);
  };

  let filteredSkills;
  let skillsObj;
  if (!isLoading && loadedSkills) {
    if (skillType === 'all') {
      filteredSkills = loadedSkills;
    } else {
      filteredSkills = loadedSkills.filter((skill) => {
        return skill.type === skillType;
      });
    }
    skillsObj = [...filteredSkills].sort((a, b) => a.name > b.name ? 1 : -1 );
    skillsObj = [...skillsObj, WAVING];
  }

  return (
    <>
    <ErrorModal error={error} onClear={clearError} />
    <SkillsContainer  id="to-skills">
      <div>
        {isLoading ? (
          <div className="center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div>
              <h2>My Skills</h2>
              <div className="select-wrapper">
                <select
                  name="skills"
                  id="skills-select"
                  onChange={changeTargetValue}
                >
                  <option value="all">All</option>
                  <option value="languages">Languages</option>
                  <option value="libraries">Libraries &amp; Frameworks</option>
                  <option value="databases">Databases</option>
                  <option value="tooling">Tooling</option>
                  <option value="soft">Soft Skills</option>
                </select>
              </div>
            </div>
            <ul className="skills">
              {!isLoading &&
                loadedSkills &&
                skillsObj.map((item, index) => {
                  return <SingleSkill key={index} item={item} index={index} />;
                })}
            </ul>
          </>
        )}
      </div>
    </SkillsContainer>
    </>
  );
};

export default Skills;
