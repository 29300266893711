import React from 'react';
import HomepageHeader from './header/HomepageHeader';
import Timeline from '../homepage/timeline/Timeline';
import Skills from './skills/Skills';
import Portfolio from './portfolio/Portfolio';


const Homepage = () => {
    return (
        <div>
            <HomepageHeader />
            <Timeline />
            <Portfolio />
            <Skills />
            
        </div>
    );
};

export default Homepage;