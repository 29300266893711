import styled from 'styled-components';


export const FooterContainer = styled.div`
    padding: 9rem 0 0;
    background: #141517;
    
    > div {
        max-width: 1200px;
        margin: 0 auto;

        .row {
            
            &.top {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                h2 {
                    font-size: 5rem;
                    color: #fff;
                    width: 60%;
                }
            }
            &.mid {
                position: relative;
                height: 250px;

                button {
                    box-shadow: none;
                    height: 230px;
                    width: 230px;
                    border: none;
                    padding: 0;
                    margin: 0;
                    border-radius: 50%;
                    background: linear-gradient(to right,#5ee19b,#65cf96);
                    font-size: 2rem;
                    font-weight: 700;
                    color: #fff;
                    position: absolute;
                    right: 365px;
                    top: -115px;

                    &:hover {
                        cursor:pointer;
                    }
                }
            }
            &.bot {
                display: flex;
                justify-content: space-between;
                padding-bottom: 9rem;

                .email {
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: #fff;

                    a {
                        color: #fff;
                        text-decoration: none;
                    }

                    svg {
                        width: 28px;
                        height: 28px;
                        position: relative;
                        top: 6px;
                        left: 12px;
                    }
                }
                .links {
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        position: relative;
                        top: 10px;

                        li {
                            color: #fff;
                            display: inline-block;
                            font-size: 1.2rem;
                            font-weight: 600;
                            color: #fff;
                            margin-right: 1rem;

                            a {
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1300px) {
        > div {
            max-width: 94%;
            margin: 0 3%;

            .row.mid {
                button {
                    right: 250px;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        > div {
            .row.top {
                h2 {
                    font-size: 4rem;
                }
            }
        }
    }

    @media (max-width: 900px) {
        > div {
            .row.top {
                h2 {
                    font-size: 4rem;
                }
            }
            .row.mid {
                button {
                    right: 220px;
                    height: 180px;
                    width: 180px;
                    top: -90px;
                    font-size: 1.6rem;
                }
            }
        }
    }

    @media (max-width: 730px) {
        > div {
            .row.top {
                h2 {
                    font-size: 3.2rem;
                }
            }
            .row.mid {
                button {
                    right: 180px;
                    height: 140px;
                    width: 140px;
                    top: -70px;
                    font-size: 1.4rem;
                }
            }
        }
    }

    @media (max-width: 630px) {
        > div {
            .row.top {
                h2 {
                    font-size: 3rem;
                    width: 80%;
                }
            }
            .row.mid {
                button {
                    right: 180px;
                    height: 140px;
                    width: 140px;
                    top: -70px;
                    font-size: 1.4rem;
                }
            }
        }
    }

    @media (max-width: 580px) {
        > div {
            .row.top {
                h2 {   
                    width: 100%;
                    margin-bottom: 90px;
                }
            }
            .row.mid {
                button {
                    right: 180px;
                    height: 140px;
                    width: 140px;
                    top: -70px;
                    font-size: 1.4rem;
                }
            }
            .row.bot {
                flex-direction: column;
            }
        }
    }
`