import styled from 'styled-components';

//background: linear-gradient(to right, #4fb0c6, #3895ab); rgba(34, 46, 63,.7)

export const TimelineContainer = styled.div`
  height: 2200px;
  background: linear-gradient(to bottom, #222e3f, #19222e);

  > div {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 6rem 0;
    height: 100%;


    h2 {
      margin: 0 0 3rem;
      font-size: 4rem;
      color: #fff;
      text-align: center;
    }

    > div {
      height: 100%;

      &:after {
        content: '';
        clear: both;
        display: table;
      }
      .timeline {
        width: 4px;
        height: 1800px;
        position: absolute;
        left: 50%;
        margin-left: -2px;
        background: #fff;
        max-height: 1800px !important;
      }

      .timeline-card {
        background: #fff;
        box-shadow: 0px 2px 10px rgba(0, 25, 13, 0.05);
        border-radius: 7px;
        opacity: 0;
        transform: translateY(50px);
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 540px;

        &.visible {
          opacity: 1;
          transform: translateY(0);
          transition: 1s all ease;
        }

        &:hover {
          cursor: pointer;
        }

        .timeline-card-header {
          padding: 2rem 2rem 1rem;
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
          position: relative;
          display: flex;
          justify-content: space-between;

          .year {
            background: #fff;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            position: absolute;
            font-weight: 700;
            font-size: 18px;
            line-height: 45px;
            text-align: center;
          }

          &:after {
            position: absolute;
            content: '';
            width: 25px;
            height: 25px;
            background: #fff;
            top: 21px;
            transform: rotate(45deg);
          }

          h3 {
            margin: 0;
            font-size: 1.5rem;
            font-weight: 700;
            display: inline-block;
          }
        }

        .timeline-card-body {
          padding: 0 2rem 2rem;

          p {
            margin: 0;
            width: 80%;
          }
          button {
            color: #fff;
            font-size: 13px;
            font-weight: 700;
            box-shadow: none;
            height: 32px;
            border: none;
            padding: 0;
            margin: 0;
            width: 120px;
            border-radius: 5px;
            background: linear-gradient(to right, #5ee19b, #65cf96);
            margin-top: 1rem;

            svg {
              position: relative;
              top: 2px;
            }
          }
        }

        &.left {
          position: absolute;
          left: 0;

          .timeline-card-header {
            &:after {
              left: 100%;
              z-index: 12;
              margin-left: -16px;
            }
            .year {
              top: 12px;
              right: -82px;
            }
          }
        }
        &.right {
          position: absolute;
          right: 0;

          .timeline-card-header {
            &:after {
              right: 100%;
              z-index: 12;
              margin-right: -16px;
            }
            .year {
              top: 12px;
              left: -82px;
            }
          }
        }
        &.card0 {
          top: 242px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
        &.card1 {
          top: 442px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
        &.card2 {
          top: 642px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
        &.card3 {
          top: 842px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
        &.card4 {
          top: 1042px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
        &.card5 {
          top: 1242px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
        &.card6 {
          top: 1442px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
        &.card7 {
          top: 1642px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
        &.card8 {
          top: 1842px;
          .timeline-card-header {
            img {
              height: 28px;
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1250px) {
    > div {
      max-width: 980px;
      > div {
        .timeline-card {
          max-width: 446px;

          .timeline-card-body {
            p {
              width: 100%;
            }
          }

          &.left {
            .timeline-card-header {
              .year {
                right: -66px;
              }
            }
          }
          &.right {
            .timeline-card-header {
              .year {
                left: -66px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1020px) {
    height: 2280px;

    > div {
      max-width: 980px;
      > div {
        .timeline {
          margin-left: 237px;
          max-height: 1890px !important;
        }
        .timeline-card {
          max-width: 446px;

          .timeline-card-body {
             p{
              width: 100%;
             }
          }

          &.right {
            left: 50%;
            right: auto;
            margin-left: -250px;
            .timeline-card-header {
              &:after {
                right: auto;
                left: 100%;
                margin-right: 0;
                margin-left: -16px;
              }
              .year {
                right: -66px;
                left: auto;
              }
            }
          }
          &.left {
            left: 50%;
            margin-left: -250px;
            .timeline-card-header {
              .year {
                right: -66px;
              }
            }
          }
          &.card1 {
            top: 458px;
          }
          &.card2 {
            top: 674px;
          }
          &.card3 {
            top: 890px;
          }
          &.card4 {
            top: 1106px;
          }
          &.card5 {
            top: 1322px;
          }
          &.card6 {
            top: 1538px;
          }
          &.card7 {
            top: 1754px;
          }
          &.card8 {
            top: 1970px;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    > div {
      > div {
        .timeline {
          margin-left: 212px;
        }
        .timeline-card {
          text-align: center;
          height: 200px;
          max-width: 396px;
          margin-left: -225px !important;

          .timeline-card-header {
            display: block;
            padding: 1rem;
            h3 {
              display: block;
            }
          }
          .timeline-card-body {
            padding: 0 1rem 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 550px) {
    > div {
      > div {
        .timeline {
          margin-left: 185px;
        }
        .timeline-card {
          max-width: 352px;
          margin-left: -205px !important;

          .timeline-card-header {
            padding: 1rem 0.8rem 1rem;

            .year {
              right: -58px !important;
              top: 16px;
              width: 38px;
              height: 38px;
              font-size: 15px;
              line-height: 38px;
            }
          }
          .timeline-card-body {
            padding: 0 0.8rem 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 430px) {
    > div {
      > div {
        .timeline {
          margin-left: 174px;
        }
        .timeline-card {
          max-width: 326px;
          margin-left: -190px !important;
        }
      }
    }
  }

  @media (max-width: 400px) {
    > div {
      > div {
        .timeline {
          margin-left: 162px;
        }
        .timeline-card {
          max-width: 300px;
          margin-left: -176px !important;
        }
      }
    }
  }
`;
