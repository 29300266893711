import React, { useCallback, useState } from 'react';
import { useScrollingHook } from '../../../hooks/ScrollingHook';
import PortfolioItem from './PortfolioItem';
import { PortfolioContainer } from './PortfolioStyles';

const portfolioImages = [
  {
    imageUrl: 'https://jamododger.herokuapp.com/assets/images/sv_comp.jpg',
    client: 'Search Visibility',
    bgColour: '#222e3f',
    url: 'https://jameswaterhouse.dev/'
  },
  {
    imageUrl: 'https://jamododger.herokuapp.com/assets/images/Bol_comp.jpg',
    client: 'Bol',
    bgColour: '#E7E7E7',
    url: 'https://bolfoods.com/' 
  },
  {
    imageUrl: 'https://jamododger.herokuapp.com/assets/images/sungod750_comp.jpg',
    client: 'SunGod',
    bgColour: '#DBE6EF',
    url: 'https://www.sungod.co/en-gb/'
  },
  {
    imageUrl: 'https://jamododger.herokuapp.com/assets/images/NBH-SS_comp.jpg',
    client: 'Neighbourhood',
    bgColour: '#E7E4E5',
    url: 'https://neighbourhood.city/'
  },
  {
    imageUrl: 'https://jamododger.herokuapp.com/assets/images/VT_comp.jpg',
    client: 'Vision Track',
    bgColour: '#DBE6EF',
    url: 'https://www.visiontrack.com/'
  },
  {
    imageUrl: 'https://jamododger.herokuapp.com/assets/images/Luna_comp.jpg',
    client: 'Luna Daily',
    bgColour: '#E7E4E5',
    url: 'https://luna-daily.com/'
  },
  {
    imageUrl: 'https://jamododger.herokuapp.com/assets/images/EG_comp.jpg',
    client: 'Epic Geek',
    bgColour: '#DBD9D5',
    url: 'https://jameswaterhouse.dev/'
  },
  {
    imageUrl: 'https://jamododger.herokuapp.com/assets/images/TC_comp.jpg',
    client: 'Tech Circus',
    bgColour: '#222e3f',
    url: 'https://techcircus.io/'
  },
];

const Portfolio = () => {
  const [shiftPortfolio, setShiftPortfolio] = useState(0);

  const listenToScroll = useCallback((e) => {
    const scrollPosition =
      document.body.scrollTop || document.documentElement.scrollTop;

    const portfolioPosition =
      window.scrollY +
      document.querySelector('.portfolio.top').getBoundingClientRect().top;

    const windowHeight = window.innerHeight;
    let scrollEqualiser = scrollPosition + windowHeight - portfolioPosition;
    if (scrollEqualiser) {
      setShiftPortfolio(scrollEqualiser / 10);
    } else {
      setShiftPortfolio(0);
    }
  }, []);

  useScrollingHook(listenToScroll);

  let lineOne = [];
  let lineTwo = [];
  for (var i = 0; i < portfolioImages.length; i++) {
    if (i < portfolioImages.length / 2) {
      lineOne.push(
        <PortfolioItem
          key={i}
          item={portfolioImages[i]}
          idx={i}
        ></PortfolioItem>
      );
    } else {
      lineTwo.push(
        <PortfolioItem
          key={i}
          item={portfolioImages[i]}
          idx={i}
        ></PortfolioItem>
      );
    }
  }

  const stylesToRight = {
    transform: `translateX(${shiftPortfolio}px)`,
  };
  const stylesToLeft = {
    transform: `translateX(-${shiftPortfolio}px)`,
  };

  return (
    <PortfolioContainer id="to-work">
      <div className="title-wrap"><h2>My Work</h2></div>
      <div className="portfolio top clearfix" style={stylesToRight}>
        {lineOne}
      </div>
      <div className="portfolio bottom clearfix" style={stylesToLeft}>
        {lineTwo}
      </div>
    </PortfolioContainer>
  );
};

export default Portfolio;
