import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Backdrop from './Backdrop';
import { ModalContainer } from './ModalStyles';

const ModalOverlay = (props) => {
  const content = (
    <ModalContainer className={`modal ${props.className}`}>
      <header className="modal-header"></header>
      <div className={`modal-content ${props.contentClass}`}>
        {props.children}
      </div>
    </ModalContainer>
  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const Modal = props => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
      
    </React.Fragment>
  );
};

export default Modal;