import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks';
import { PrimaryNavigationContainer } from './PrimaryNavigationStyles';
import SideDrawer from './SideDrawer';
import Backdrop from '../modals/Backdrop';

const PrimaryNavigation = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <PrimaryNavigationContainer>
      <div className="row-max">
        <span className="logo">
          <Link to="/">
            <h1>
              James <span>Waterhouse</span>
            </h1>
            <span className="sub-logo">{`{Developer for hire}`}</span>
          </Link>
        </span>
        <NavLinks className="desktop-nav" />
        {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
        <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
          <NavLinks className="mobile-nav" />
        </SideDrawer>
        <button className="nav-hamburger" onClick={drawerIsOpen ? closeDrawerHandler : openDrawerHandler}>
          <span className="top"></span>
          <span className="middle"></span>
          <span className="bottom"></span>
        </button>
      </div>
    </PrimaryNavigationContainer>
  );
};

export default PrimaryNavigation;
