import React from 'react';

import {SpinnerContainer} from './LoadingSpinnerStyles';

const LoadingSpinner = props => {
  return (
    <SpinnerContainer className={`${props.asOverlay && 'loading-spinner__overlay'}`}>
      <div className="lds-dual-ring"></div>
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
