import React from 'react';
import Carousel from './Carousel';

const HomepageHeader = () => {
    return (
        <div>
            <Carousel />
        </div>
    );
};

export default HomepageHeader;